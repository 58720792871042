<template>
  <div>
    <v-row class="d-flex justify-center">
      <v-col lg="3" class="d-flex justify-center">
        <v-card
          class="d-flex flex-column align-center"
          width="800"
          elevation="0"
          color="transparent"
        >
          <v-row v-if="state === 0" class="flex-column align-center px-5">
            <p class="validationtitle text-center bigpaddingtop">
              Just a sec...
            </p>
            <v-progress-linear
              class="mt-6"
              style="width: 25%"
              color="primary"
              height="8"
              indeterminate
              rounded
            ></v-progress-linear>
            <p class="mt-5 mb-10 validationdesc text-center">
              We just need a second to work some stuff out.
            </p>

            <p class="bigpaddingtop text-center"></p>
            <p class="bigpaddingtop text-center"></p>
          </v-row>

          <v-row v-else-if="state === 1" class="flex-column align-center px-5">
            <p class="validationtitle text-center bigpaddingtop">
              Something went wrong
            </p>
            <p class="mt-6 homepagedesc text-center">
              <v-icon x-large color="red">mdi-alert-octagon-outline</v-icon>
            </p>
            <p class="mt-5 validationdesc text-center">
              The delete token is invalid or has expired.<br /><br />
              Please try again by submitting a new request.
            </p>
            <p class="mt-7">
              <v-btn
                @click="$router.push('/')"
                width="160px"
                height="50px"
                color="primary"
                >Home</v-btn
              >
            </p>

            <p class="bigpaddingtop text-center"></p>
          </v-row>

          <v-row v-else-if="state === 2" class="flex-column align-center px-5">
            <p class="validationtitle text-center bigpaddingtop">
              Confirm Account Deletion
            </p>
            <p class="mt-2 validationdesc text-center">
              Deleting an account will completely erase all captures, data and
              information.
              <br /><br />
              Please enter your account password to confirm the deletion.
            </p>
            <v-form ref="form" v-model="isFormValid">
              <v-text-field
                outlined
                dense
                full-width
                single-line
                class="mb-0 mt-2 py-0"
                name="password"
                label="Account Password"
                type="password"
                v-model="password"
                :rules="[rules.required]"
              ></v-text-field>
              <p class="mt-2">
                <v-btn
                  @click="DeleteAccount()"
                  width="220px"
                  height="50px"
                  color="primary"
                  :disabled="!isFormValid"
                  >Delete Account</v-btn
                >
              </p>
            </v-form>

            <p class="bigpaddingtop text-center"></p>
          </v-row>
          <v-row v-else-if="state === 3" class="flex-column align-center px-5">
            <p class="validationtitle text-center bigpaddingtop">
              Account Deleted
            </p>
            <p class="mt-6 homepagedesc text-center">
              <v-icon x-large color="green">mdi-check-outline</v-icon>
            </p>
            <p class="mt-5 validationdesc text-center">
              Your account has been successfully deleted.<br /><br />All
              captures, data and information has been deleted from ours servers.
              <br /><br />
              Please take a minute and
              <a @click="OpenSurvey()">tell us what we did wrong</a>. We're
              trying our best to improve our service.
            </p>
            <p class="mt-7">
              <v-btn
                @click="$router.push('/')"
                width="200px"
                height="50px"
                color="primary"
                >Home</v-btn
              >
            </p>

            <p class="bigpaddingtop text-center"></p>
          </v-row>
          <v-row v-else-if="state === 4" class="flex-column align-center px-5">
            <p class="validationtitle text-center bigpaddingtop">
              Permission Denied
            </p>
            <p class="mt-6 homepagedesc text-center">
              <v-icon x-large color="red">mdi-alert-octagon-outline</v-icon>
            </p>
            <p class="mt-5 validationdesc text-center">
              You do not have permission to delete this account, this could
              happen if the password entered was wrong<br /><br />
              Please try again by submitting a new request.
            </p>
            <p class="mt-7">
              <v-btn
                @click="$router.push('/')"
                width="160px"
                height="50px"
                color="primary"
                >Home</v-btn
              >
            </p>

            <p class="bigpaddingtop text-center"></p>
          </v-row>
        </v-card>

        <!-- survey sent successfully -->
        <v-dialog max-width="400" v-model="survey">
          <template>
            <v-card>
              <v-toolbar color="primary" dark
                >Account Deletion Survey</v-toolbar
              >
              <v-card-text>
                <div class="pt-4 pb-0 text--primary">
                  Please select from the following that made you delete your
                  account with us.
                  <v-checkbox
                    class="ma-0 mt-4 pa-0"
                    v-model="selected"
                    label="The application was too slow"
                    value="The application was too slow"
                  ></v-checkbox>
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="selected"
                    label="Lack of updates"
                    value="Lack of updates"
                  ></v-checkbox>
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="selected"
                    label="I found a better service elsewhere"
                    value="I found a better service elsewhere"
                  ></v-checkbox>
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="selected"
                    label="It costs too much"
                    value="It costs too much"
                  ></v-checkbox>
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="selected"
                    label="I found the application/website too difficult to use"
                    value="I found the application/website too difficult to use"
                  ></v-checkbox>
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="selected"
                    label="I just wanted to delete my account"
                    value="I just wanted to delete my account"
                  ></v-checkbox>
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text color="green" @click="PostSurvey()"
                  >Send Feedback</v-btn
                >
                <v-btn text color="primary" @click="survey = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

        <v-dialog max-width="400" v-model="surveySent">
          <template>
            <v-card>
              <v-toolbar color="primary" dark>Feedback Submitted</v-toolbar>
              <v-card-text>
                <div class="pt-4 pb-0 text--primary">
                  Thank you for submitting your feedback.
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text color="primary" @click="surveySent = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

        <v-dialog max-width="400" v-model="surveyErr">
          <template>
            <v-card>
              <v-toolbar color="primary" dark>Something went wrong!</v-toolbar>
              <v-card-text>
                <div class="pt-4 pb-0 text--primary">
                  There was a problem submitting your feedback to us. Please try again later.
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text color="primary" @click="surveyErr = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import config from "../config";
export default {
  name: "Validation",
  data: function () {
    return {
      state: 0,
      message: "",
      password: "",
      survey: false,
      surveySubmitted: false,
      surveySent: false,
      surveyErr: false,
      selected: ["I just wanted to delete my account"],
      isFormValid: false,
      rules: {
        required: (value) => !!value || "Required.",
        requiredPassword: (value) =>
          value == this.password || "Password must match",
      },
    };
  },
  methods: {
    Logout() {
      this.$store.commit("disconnectUser");
    },
    OpenSurvey() {
      if (!this.surveySubmitted) {
        this.survey = true;
      }
    },
    DeleteAccount() {
      fetch(`${config.URL}/users/deleteacc`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: this.$route.params.id,
          password: this.password,
        }),
      }).then((res) => {
        res.json().then((data) => {
          this.state = data.state;
          if (this.state == 3) {
            this.Logout();
          }
        });
      });
    },
    PostSurvey() {
      this.surveySubmitted = true;
      this.survey = false;
      fetch(`${config.URL}/users/deleteaccsurvey`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          params: this.selected,
        }),
      }).then((res) => {
        if (res.status == 200) {
          this.surveySent = true;
          this.surveyErr = false;
        } else {
          this.surveyErr = true;
          this.surveySent = false;
        }
      });
    },
  },
  mounted: function () {
    fetch(`${config.URL}/users/delete`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: this.$route.params.id }),
    }).then((res) => {
      res.json().then((data) => {
        this.state = data.state;
      });
    });
  },
};
</script>

<style>
.validationtitle {
  font-size: 35px;
  font-weight: bold;
  line-height: 2.2rem;
  color: rgb(12, 12, 12) !important;
}

.validationdesc {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2rem;
  color: rgb(12, 12, 12) !important;
}

.bigpaddingtop {
  padding-top: 5.5rem;
}
</style>
