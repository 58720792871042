<script>
var addLineNumbers = require('add-line-numbers');
export default {
  props: {
    code: {
      type: String,
      default: ""
    },
    lang: {
      type: String,
      default: ""
    }
  },
  data: function(){
    return {
      class: "language-"
    }
  },
  template: "<pre :class='getClassLang' ><code :class='getClassLang'>{{highlightedCode}}</code></pre>",
  computed: {
    getClassLang: function(){
      return this.class + this.lang;
    },
    highlightedCode: function() {
      return addLineNumbers(this.code, 1, "  ");
    }
  }       
}
</script>

<style>
code[class*="language-"], pre[class*="language-"] {
  white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
</style>