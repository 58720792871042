<template>
<div>
    <v-row class="d-flex justify-center">
      <v-col lg="3" class="d-flex justify-center">
        <v-card class="d-flex flex-column" width="450" elevation="0" color="transparent">
        <p class="homepagetitle">
          Sharing is caring
        </p>
        <p class="homepagedesc">
          We believe Onyshare is the fastest and easiest screenshot capture tool out there.<br><br> Don't take our word for it, try it for free.
        </p>
        <v-btn width="65%" height="50px" color="primary">Get Started</v-btn>
      </v-card>
      </v-col>
      <v-col lg="3" sm="8" class="d-flex justify-center">
        <v-img src="../assets/images/undraw_camera_re_cnp4.svg"></v-img>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center bigpaddingtop">
      <v-col lg="3" class="d-flex justify-center">
        <v-card class="d-flex justify-center" width="450" elevation="0" color="transparent">
          <!-- <v-img src="https://via.placeholder.com/450x600"></v-img> -->
          <v-img src="../assets/images/undraw_personal_settings_re_i6w4.svg"></v-img>
        </v-card>
      </v-col>
      <v-col lg="3" class="d-flex justify-center">
        <v-card class="d-flex flex-column" width="450" elevation="0" color="transparent">
          <p class="homepagetitle">
            Limitless Options
          </p>
          <p class="homepagedesc">
            Onyshare comes with a bunch of user options and settings so you can configurate our app your way.
          </p>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center bigpaddingtop">
      <v-col lg="3" class="d-flex justify-center">
        <v-card class="d-flex flex-column" width="450" elevation="0" color="transparent">
          <p class="homepagetitle">
            Configurable Shortcuts
          </p>
          <p class="homepagedesc">
            Easily change the capture hot keys, video shortcuts and more with an easy to use rebindable shortcut menu.
          </p>
        </v-card>
      </v-col>
      <v-col lg="3" class="d-flex justify-center">
        <v-card class="d-flex justify-center" width="450" elevation="0" color="transparent">
          <!-- <v-img src="https://via.placeholder.com/450x600"></v-img> -->
          <v-img src="../assets/images/undraw_advanced_customization_re_wo6h.svg"></v-img>
        </v-card>
      </v-col>
    </v-row>

        <v-row class="d-flex justify-center bigpaddingtop">
      <v-col lg="3" class="d-flex justify-center">
        <v-card class="d-flex justify-center" width="450" elevation="0" color="transparent">
          <!-- <v-img lazy-src="https://picsum.photos/id/11/10/6" src="../images/sniprshot-instantcapture.png" max-height="200"></v-img> -->
          <v-img src="../assets/images/undraw_image_viewer_re_7ejc.svg"></v-img>
        </v-card>
      </v-col>
      <v-col lg="3" class="d-flex justify-center">
        <v-card class="d-flex flex-column" width="450" elevation="0" color="transparent">
          <p class="homepagetitle">
            Snapshot Capture Mode
          </p>
          <p class="homepagedesc">
              Onyshare is capable of capturing screenshots in different ways. Other than the default behavior you are used to, Snapshot Capture Mode instantly snaps the screen on capture key, meaning it's perfect for developers who want to capture tooltips, popovers and more!<br><br>
              It's not just for developers, it's great for anyone who wants to crop a snapshot of the screen.<br><br>
              You can't do this with any other screen capture software!
          </p>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center bigpaddingtop">
      <v-col lg="3" class="d-flex justify-center">
        <v-card class="d-flex flex-column" width="450" elevation="0" color="transparent">
          <p class="homepagetitle">
            Laptop Friendly
          </p>
          <p class="homepagedesc">
            Enable Toggle Capture in the settings for one click capture of anything you want.<br><br> Perfect for users on track pads where holding a button can be difficult.
          </p>
        </v-card>
      </v-col>
      <v-col lg="3" class="d-flex justify-center">
        <v-card class="d-flex justify-center" width="450" elevation="0" color="transparent">
          <!-- <v-img src="https://via.placeholder.com/450x600"></v-img> -->
          <v-img src="../assets/images/undraw_shared_workspace_re_3gsu.svg"></v-img>
        </v-card>
      </v-col>
    </v-row>   

            <v-row class="d-flex justify-center bigpaddingtop">
      <v-col lg="3" class="d-flex justify-center">
        <v-card class="d-flex justify-center" width="450" elevation="0" color="transparent">
          <!-- <v-img lazy-src="https://picsum.photos/id/11/10/6" src="../images/sniprshot-instantcapture.png" max-height="200"></v-img> -->
          <v-img src="../assets/images/undraw_uploading_re_okvh.svg"></v-img>
        </v-card>
      </v-col>
      <v-col lg="3" class="d-flex justify-center">
        <v-card class="d-flex flex-column" width="450" elevation="0" color="transparent">
          <p class="homepagetitle">
            Your Capture, Forever
          </p>
          <p class="homepagedesc">
              After capturing, your captures will be automatically saved to the cloud forever.<br><br>
              Manage, delete and share your captures all within your Dashboard.
          </p>
        </v-card>
      </v-col>
    </v-row> 

      <v-row class="d-flex justify-center bigpaddingtop">
        <v-col lg="3" sm="12" class="d-flex justify-center">
          <v-card class="d-flex flex-column align-center" elevation="0" color="transparent">
            <p class="homepagetitle text-center">Get Started Today</p>
            <p class="text-center">Now you're up to speed with Onyshare, choose your plan to get started.</p>
            <br />
            <p class="text-center">Enjoy free and unlimited updates and support on all packages.</p>
            <br />
          </v-card>
        </v-col>
        <v-col lg="2" class="d-flex justify-center">
          <v-card class="d-flex flex-column justify-space-between align-center pa-3" min-height="320" width="300" elevation="5" color="transparent">
            <p class="tiercardtitle">Onyshare Basic</p>
            <p class="pt-5 pb-1 text-center">Try Onyshare basic for an unlimited time, completely free.</p>
            <div class="pb-5"><span class="pricetitle">$0</span><span class="pricemonth pt-5">/month</span></div>
            <v-btn outlined height="40px" width="90%" color="primary">Get Started</v-btn>
          </v-card>
        </v-col>
        <v-col lg="2" class="d-flex justify-center">
          <v-card class="d-flex flex-column justify-space-between align-center pa-3" min-height="320" width="300" elevation="5" color="transparent">
            <p class="tiercardtitle">Onyshare Pro</p>
            <p class="pt-5 pb-1 text-center">Onyshare without limitations. Everything you need.</p>
            <div class="pb-5"><span class="pricetitle">$2</span><span class="pricemonth pt-5">/month</span></div>
            <v-btn outlined height="40px" width="90%" color="primary">Get Onyshare Pro</v-btn>
          </v-card>
        </v-col>
      </v-row>


    <v-row class="justify-center bigpaddingtop">
      <v-col class="d-flex align-center flex-column" lg="4">
       <!-- <v-img class="text-center" width="100%" src="https://via.placeholder.com/600x350"></v-img> -->
       <v-img src="../assets/images/undraw_my_files_swob.svg"></v-img>     
       <p class="homepagetitle text-center my-7">Start sharing with Onyshare</p>
       <v-btn outlined width="50%" class="text-center" height="40px" color="primary" to='/download'>Download for free</v-btn>
      </v-col>
    </v-row>

    </div>
</template>

<style scoped>
.homepagetitle {
  font-size: 35px;
  font-weight: bold;
  line-height: 2.2rem;
  color: rgb(12, 12, 12) !important;
}

.homepagedesc {
  font-size: 17px;
  color: rgb(12, 12, 12) !important;
}

.homepagecard {
  font-size: 17px;
  color: rgb(12, 12, 12) !important;
  font-weight: bolder;
}

.tiercardtitle {
  font-size: 20px;
  color: rgb(12, 12, 12) !important;
  font-weight: bolder;
}

.bigpaddingtop {
  padding-top: 10rem;
}

.pricetitle {
  font-weight: 600;
  font-size: 30px;
}

.pricemonth {
  font-weight: 400;
  font-size: 15px;
}
</style>

<script>
export default {
  name: 'Features',
};
</script>
