<template>
  <div>
    <v-row class="d-flex justify-center">
      <v-col lg="12" class="d-flex justify-center">
        <v-card class="d-flex flex-column align-center" width="800" elevation="0" color="transparent">
          <p class="notfoundtitle text-center">Terms of Service</p>
          <p class="homepagedesc text-left">
            <strong>1. Overview</strong> 
            <br/>
            This Terms of Service (“Terms”) is between you and Onyshare (“Company”) and constitutes a legally
            binding agreement governing your use of the screenshot save and upload service (the “Service”) offered by
            Company. By using the Service, you agree to comply with all of the Terms set forth herein.
            <br/>
            <br/>
            <strong>2. Usage</strong>
            <br/>
            2.1 You are solely responsible for your use of the Service and for any data, text, files, information,
            usernames, images, graphics, photos, profiles, audio and video clips, sounds, musical works, works of
            authorship, applications, links and other content or materials (collectively, “Content”) that you submit,
            post or display on or through the Service.
            <br/>
            2.2 You may not use the Service for any illegal or unauthorized purpose. You are solely responsible for your
            conduct and any Content that you submit, post, and display on or through the Service.
            <br/>
            <br/>
            <strong>3. License</strong>
            <br/>
            3.1 By posting or submitting Content on or through the Service, you hereby grant Company a perpetual,
            irrevocable, worldwide, non-exclusive, royalty-free, sub-licensable and transferable license to use,
            reproduce, distribute, prepare derivative works of, display, and perform the Content in connection with the
            Service and Company’s (and its successors’ and affiliates’) business, including without limitation for
            promoting and redistributing part or all of the Service (and derivative works thereof) in any media formats
            and through any media channels.
            <br/>
            3.2 You represent and warrant that: (i) you own the Content posted by you on or through the Service or
            otherwise have the right to grant the license set forth in this Section, and (ii) the posting of your
            Content on or through the Service does not violate the privacy rights, publicity rights, copyrights,
            contract rights or any other rights of any person.
            <br/>
            <br/>
            <strong>4. Privacy</strong>
            <br/>
            Company will not use or disclose your personal information except as set forth in the Company Privacy
            Policy.
            <br/>
            <br/>
            <strong>5. Third Party Services</strong>
            <br/>
            The Service may contain links to third-party websites or services that are not owned or controlled by
            Company. Company has no control over, and assumes no responsibility for, the content, privacy policies, or
            practices of any third-party websites or services. You further acknowledge and agree that Company shall not
            be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by
            or in connection with use of or reliance on any such content, goods or services available on or through any
            such websites or services.
            <br/>
            <br/>
            <strong>6. Termination</strong>
            <br/>
            Company reserves the right, in its sole discretion, to modify or discontinue the Service or to terminate
            your access to the Service, at any time and without notice. You agree that Company shall not be liable to
            you or to any third-party for any modification, suspension or discontinuance of the Service.
            <br/>
            <br/>
            <strong>7. Disclaimer of Warranties</strong>
            <br/>
            YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
            BASIS. COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NON-INFRINGEMENT.
            <br/>
            <br/>
            <strong>8. Limitation of Liability</strong>
            <br/>
            COMPANY SHALL NOT BE LIABLE FOR ANY DAMAGES OR LOSSES RESULTING FROM YOUR USE OF THE SERVICE. UNDER NO
            CIRCUMSTANCES SHALL COMPANY BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY
            DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
            LOSSES (EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM YOUR USE OF THE
            SERVICE.
            <br/>
            <br/>
            <strong>9. Miscellaneous</strong>
            <br/>
            9.1 These Terms constitute the entire agreement between you and Company regarding the use of the Service,
            superseding any prior agreements between you and Company relating to your use of the Service.
            <br/>
            9.2 The failure of Company to exercise or enforce any right or provision of the Terms shall not constitute a
            waiver of such right or provision.
            <br/>
            9.3 If any provision of these Terms is held to be invalid or unenforceable by a court of competent
            jurisdiction, the remaining provisions of these Terms will remain in full force and effect.
            <br/>
            9.4 You agree that these Terms and any claim or dispute arising out of or related to these Terms or the
            Service will be governed by the laws of the [State/Province] without regard to its conflict of law
            provisions.
            <br/>
            9.5 Any claim or dispute between you and Company that arises in whole or in part from the Service will be
            decided exclusively by a court of competent jurisdiction located in England, UK.
            <br/>
            <br/>
            <strong>10. Contact</strong>
            <br/>
            If you have any questions about these Terms, please contact us.
            <br/>
            <br/>
            You can also read our <a @click="$router.push('/privacy')">Privacy Policy</a>.
          </p>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.notfoundtitle {
  font-size: 55px;
  font-weight: bold;
  line-height: 2.2rem;
  color: rgb(12, 12, 12) !important;
}

.homepagedesc {
  font-size: 17px;
  color: rgb(12, 12, 12) !important;
}

.homepagecard {
  font-size: 17px;
  color: rgb(12, 12, 12) !important;
  font-weight: bolder;
}

.tiercardtitle {
  font-size: 20px;
  color: rgb(12, 12, 12) !important;
  font-weight: bolder;
}

.bigpaddingtop {
  padding-top: 8rem;
}

.pricetitle {
  font-weight: 600;
  font-size: 30px;
}

.pricemonth {
  font-weight: 400;
  font-size: 15px;
}
</style>

<script>
export default {
  name: "Terms",
  data: function () {
    return {

    };
  },
  methods: {
  },
};
</script>

