<template>
  <div>
    <v-row class="d-flex justify-center">
      <v-col lg="3" class="d-flex justify-center">
        <v-card
          class="d-flex flex-column align-center"
          width="450"
          elevation="0"
          color="transparent"
        >
          <p class="notfoundtitle text-center bigpaddingtop">404</p>
          <p class="homepagedesc text-center">
            {{ GetRandomMessage() }}
          </p>
            <p class="mt-7"><v-btn @click="$router.push('/')" width="160px" height="50px" color="primary">Home</v-btn></p>
            
            <p class="bigpaddingtop text-center"></p>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.notfoundtitle {
  font-size: 55px;
  font-weight: bold;
  line-height: 2.2rem;
  color: rgb(12, 12, 12) !important;
}

.homepagedesc {
  font-size: 17px;
  color: rgb(12, 12, 12) !important;
}

.homepagecard {
  font-size: 17px;
  color: rgb(12, 12, 12) !important;
  font-weight: bolder;
}

.tiercardtitle {
  font-size: 20px;
  color: rgb(12, 12, 12) !important;
  font-weight: bolder;
}

.bigpaddingtop {
  padding-top: 8rem;
}

.pricetitle {
  font-weight: 600;
  font-size: 30px;
}

.pricemonth {
  font-weight: 400;
  font-size: 15px;
}
</style>

<script>
export default {
  name: "PageNotFound",
  data: function () {
    return {
      text: "Your princess is in another castle.",
    };
  },
  methods: {
    GetRandomMessage() {
      var text = [
        "Your princess is in another castle.",
        "You must construct additional pylons!",
        "You've boldly gone where no man has gone before.",
        "Oops, that page doesn't exist!",
        "Something is wrong here.",
        "The page you are looking for might have been removed or temporarily unavailable",
        "Breathe... You got this next time.",
        "This is not the page you are looking for.",
        "We can't seem to find the page you're looking for.",
        "We can't find the page you're looking for",
        "There's nothing here.",
        "The page you are looking for no longer exists.",
        "It's not here. Sad.",
        "<enter clever 404 message here>",
        "<enter joke here>",
        "The page you are looking for may have been deleted or moved.",
        "Wait, you shouldn't be here."
      ];

      var random = Math.floor(Math.random() * text.length);
      return text[random];
    },
  },
};
</script>

