<template>
<div>
    <v-row class="justify-center">
      <v-col class="d-flex align-center flex-column" lg="4">
       <p class="homepagetitle text-center my-7">Start sharing with Onyshare</p>
       <v-btn width="50%" class="text-center" height="40px" color="primary">Download for free</v-btn>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center bigpaddingtop">
      <v-col lg="2" class="d-flex justify-center">
        <v-card class="d-flex flex-column align-center" width="250" elevation="0" color="transparent">
          <!-- <v-img lazy-src="https://picsum.photos/id/11/10/6" src="https://via.placeholder.com/250"></v-img> -->
          <v-img src="../assets/images/landscape.png" contain width="164"></v-img>
          <p class="homepagecard">Screenshots</p>
          <p class="text-center">Screen capture any application, the fastest and easiest way.</p>
        </v-card>
      </v-col>
      <v-col lg="2" class="d-flex justify-center">
        <v-card class="d-flex flex-column align-center" width="250" elevation="0" color="transparent">
          <!-- <v-img lazy-src="https://picsum.photos/id/11/10/6" src="https://via.placeholder.com/250"></v-img> -->
          <v-img src="../assets/images/screenshot.png" contain width="164"></v-img>
          <p class="homepagecard">Videos</p>
          <p class="text-center">Record gameplay, create tutorials and more with video captures.</p>
        </v-card>
      </v-col>
      <v-col lg="2" class="d-flex justify-center">
        <v-card class="d-flex flex-column align-center" width="250" elevation="0" color="transparent">
          <!-- <v-img lazy-src="https://picsum.photos/id/11/10/6" src="https://via.placeholder.com/250"></v-img> -->
          <v-img src="../assets/images/image.png" contain width="164"></v-img>
          <p class="homepagecard">Region or Full Screen</p>
          <p class="text-center">Capture your screenshot and videos the way you want.</p>
        </v-card>
      </v-col>
    </v-row>
    </div>
</template>

<style scoped>

.homepagetitle {
  font-size: 35px;
  font-weight: bold;
  line-height: 2.2rem;
  color: rgb(12, 12, 12) !important;
}

.homepagedesc {
  font-size: 17px;
  color: rgb(12, 12, 12) !important;
}

.homepagecard {
  font-size: 17px;
  color: rgb(12, 12, 12) !important;
  font-weight: bolder;
}

.tiercardtitle {
  font-size: 20px;
  color: rgb(12, 12, 12) !important;
  font-weight: bolder;
}

.bigpaddingtop {
  padding-top: 10rem;
}

.pricetitle {
  font-weight: 600;
  font-size: 30px;
}

.pricemonth {
  font-weight: 400;
  font-size: 15px;
}
</style>

<script>
export default {
  name: 'Downloads',
};
</script>
