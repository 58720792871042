<template>
  <div>
    <v-row class="d-flex justify-center">
      <v-col lg="3" class="d-flex justify-center">
        <v-card
          class="d-flex flex-column align-center"
          width="800"
          elevation="0"
          color="transparent"
        >
          <v-row
            v-if="emailValidated === 0"
            class="flex-column align-center px-5"
          >
            <p class="validationtitle text-center bigpaddingtop">
              Awaiting Validation
            </p>
            <v-progress-linear
              class="mt-6"
              style="width: 25%"
              color="primary"
              height="8"
              indeterminate
              rounded
            ></v-progress-linear>
            <p class="mt-5 validationdesc text-center">
              We are processing your validation token, hold on tight!
            </p>

            <p class="bigpaddingtop text-center"></p>
          </v-row>

          <v-row
            v-else-if="emailValidated === 1"
            class="flex-column align-center px-5"
          >
            <p class="validationtitle text-center bigpaddingtop">
              Invalid Validation
            </p>
            <p class="mt-6 homepagedesc text-center">
              <v-icon x-large color="red">mdi-alert-octagon-outline</v-icon>
            </p>
            <p class="mt-5 validationdesc text-center">
              Account validation token is invalid. Please try again.
            </p>
            <p class="mt-7">
              <v-btn
                @click="$router.push('/')"
                width="160px"
                height="50px"
                color="primary"
                >Home</v-btn
              >
            </p>

            <p class="bigpaddingtop text-center"></p>
          </v-row>

          <v-row
            v-else-if="emailValidated === 2"
            class="flex-column align-center px-5"
          >
            <p class="validationtitle text-center bigpaddingtop">
              Validation Successful
            </p>
            <p class="mt-6 homepagedesc text-center">
              <v-icon x-large color="green">mdi-check-outline</v-icon>
            </p>
            <p class="mt-5 validationdesc text-center">
              Thank you for confirming your email address.<br />You may now
              log in and use Onyshare.
            </p>
            <p class="mt-7">
              <v-btn
                @click="$router.push('/')"
                width="160px"
                height="50px"
                color="primary"
                >Home</v-btn
              >
            </p>

            <p class="bigpaddingtop text-center"></p>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import config from "../config";
export default {
  name: "Validation",
  data: function () {
    return {
      emailValidated: 0,
      message: "",
    };
  },
  mounted: function () {
    fetch(`${config.URL}/users/validate`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: this.$route.params.id }),
    }).then((res) => {
      if (res.status === 200) {
        this.emailValidated = 2;
      } else {
        this.emailValidated = 1;
      }
    });
  },
};
</script>

<style>
.validationtitle {
  font-size: 35px;
  font-weight: bold;
  line-height: 2.2rem;
  color: rgb(12, 12, 12) !important;
}

.validationdesc {
  font-size: 18px;
  font-weight: normal;
  line-height: 2.2rem;
  color: rgb(12, 12, 12) !important;
}

.bigpaddingtop {
  padding-top: 5.5rem;
}
</style>
