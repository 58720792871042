<template>
  <div>
    <v-row class="d-flex justify-center">
      <v-col lg="12" class="d-flex justify-center">
        <v-card class="d-flex flex-column align-center" width="800" elevation="0" color="transparent">
          <p class="notfoundtitle text-center">Privacy Policy</p>
          <p class="homepagedesc text-left">
            <strong>Overview</strong>
            <br/>
            This Privacy Policy governs the manner in which Onyshare collects, uses, maintains and discloses
            information collected from users (each, a “User”) of the Onyshare website (“Site”). This privacy
            policy applies to the Site and all products and services offered by Onyshare.
            <br/>
            <br/>
            <strong>Personal Identification Information</strong>
            <br/>
            We may collect personal identification information from Users in a variety of ways, including, but not
            limited to, when Users visit our site, register on the site, and in connection with other activities,
            services, features or resources we make available on our Site. Users may be asked for, as appropriate, name,
            email address, mailing address, phone number. Users may, however, visit our Site anonymously. We will
            collect personal identification information from Users only if they voluntarily submit such information to
            us. Users can always refuse to supply personally identification information, except that it may prevent them
            from engaging in certain Site related activities.
            <br/>
            <br/>
            <strong>Non-Personal Identification Information</strong>
            <br/>
            We may collect non-personal identification information about Users whenever they interact with our Site.
            Non-personal identification information may include the browser name, the type of computer and technical
            information about Users means of connection to our Site, such as the operating system and the Internet
            service providers utilized and other similar information.
            <br/>
            <br/>
            <strong>Web Browser Cookies</strong>
            <br/>
            Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive
            for record-keeping purposes and sometimes to track information about them. User may choose to set their web
            browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts
            of the Site may not function properly.
            <br/>
            <br/>
            <strong>How We Use Collected Information</strong>
            <br/>
            Onyshare may collect and use Users personal information for the following purposes:
            <br/>
            - To personalize user experience
            We may use information in the aggregate to understand how our Users as a group use the services and
            resources provided on our Site.
            <br/>
            - To improve our Site
            We may use feedback you provide to improve our products and services.
            <br/>
            - To run a promotion, contest, survey or other Site feature
            <br/>
            - To send Users information they agreed to receive about topics we think will be of interest to them.
            <br/>
            - To send periodic emails
            We may use the email address to respond to their inquiries, questions, and/or other requests.
            <br/>
            <br/>
            <strong>How We Protect Your Information</strong>
            <br/>
            We adopt appropriate data collection, storage and processing practices and security measures to protect
            against unauthorized access, alteration, disclosure or destruction of your personal information, username,
            password, transaction information and data stored on our Site.
            <br/>
            <br/>
            <strong>Sharing Your Personal Information</strong>
            <br/>
            We do not sell, trade, or rent Users personal identification information to others. We may share generic
            aggregated demographic information not linked to any personal identification information regarding visitors
            and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.
            <br/>
            <br/>
            <strong>Changes to This Privacy Policy</strong>
            <br/>
            Onyshare has the discretion to update this privacy policy at any time. When we do, we will post a
            notification on the main page of our Site, revise the updated date at the bottom of this page and send you
            an email. We encourage Users to frequently check this page for any changes to stay informed about how we are
            helping to protect the personal information we collect. You acknowledge and agree that it is your
            responsibility to review this privacy policy periodically and become aware of modifications.
            <br/>
            <br/>
            <strong>Your Acceptance of These Terms</strong>
            <br/>
            By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please
            do not use our Site. Your continued use of the Site following the posting of changes to this policy will be
            deemed your acceptance of those changes.
            <br/>
            <br/>
            <strong>Contacting Us</strong>
            <br/>
            If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this
            site, please contact us.
          </p>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.notfoundtitle {
  font-size: 55px;
  font-weight: bold;
  line-height: 2.2rem;
  color: rgb(12, 12, 12) !important;
}

.homepagedesc {
  font-size: 17px;
  color: rgb(12, 12, 12) !important;
}

.homepagecard {
  font-size: 17px;
  color: rgb(12, 12, 12) !important;
  font-weight: bolder;
}

.tiercardtitle {
  font-size: 20px;
  color: rgb(12, 12, 12) !important;
  font-weight: bolder;
}

.bigpaddingtop {
  padding-top: 8rem;
}

.pricetitle {
  font-weight: 600;
  font-size: 30px;
}

.pricemonth {
  font-weight: 400;
  font-size: 15px;
}
</style>

<script>
export default {
  name: "Terms",
  data: function () {
    return {

    };
  },
  methods: {
  },
};
</script>

