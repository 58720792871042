<template>
  <div>
    <v-row class="d-flex justify-center">
      <v-col lg="3" class="d-flex justify-center">
        <!-- default -->
        <v-card
          v-if="state == 0 && !IsLoggedIn"
          class="d-flex flex-column align-center"
          min-width="400"
          elevation="0"
          color="transparent"
        >
          <p class="notfoundtitle text-center bigpaddingtop">Reset Password</p>
          <p class="homepagedesc text-center">
            Enter the email address you used when registering and we'll do our
            best to reset your password.<br /><br />Your account must be verified. <br /><br />
            You may need to check your spam/junk folder.
          </p>
          <v-form class="text-center" v-model="isFormValid">
            <v-text-field
              class="mt-2"
              outlined
              dense
              single-line
              name="email"
              label="Email Address"
              :rules="[rules.required, rules.validEmail]"
              v-model="email"
            ></v-text-field>
            <p class="mt-1 mb-0">
              <v-btn
                @click="resetPassword()"
                :disabled="!isFormValid"
                width="160px"
                height="50px"
                color="primary"
                >Reset</v-btn
              >
            </p>
          </v-form>
          <a href="/" class="mt-1"><small>Take me back</small></a>
          <p class="bigpaddingtop text-center"></p>
        </v-card>
        <!-- loading -->
        <v-card
          v-if="state == 1 && !IsLoggedIn"
          class="d-flex flex-column align-center"
          min-width="400"
          elevation="0"
          color="transparent"
        >
          <p class="notfoundtitle text-center bigpaddingtop">Reset Password</p>
          <v-progress-linear
            class="mt-10 mb-10"
            style="width: 25%"
            color="primary"
            height="8"
            indeterminate
            rounded
          ></v-progress-linear>
          <p class="homepagedesc text-center mb-10">
            Please wait while we try and reset your password.
          </p>
          <p class="bigpaddingtop"></p>
          <p class="bigpaddingtop"></p>
          <p class="mb-8"></p>
        </v-card>
        <!-- problem -->
        <v-card
          v-if="state == 2 && !IsLoggedIn"
          class="d-flex flex-column align-center"
          min-width="400"
          elevation="0"
          color="transparent"
        >
          <p class="notfoundtitle text-center bigpaddingtop">Reset Password</p>
          <p class="mt-3 homepagedesc text-center">
            <v-icon x-large color="red">mdi-alert-octagon-outline</v-icon>
          </p>
          <p class="homepagedesc text-center mb-10">
            There was a problem trying to reset this account's password.<br /><br />
            Please try again.
          </p>
          <p class="mb-0">
            <v-btn
              @click="
                state = 0;
                email = '';
              "
              width="160px"
              height="50px"
              color="primary"
              >Try again</v-btn
            >
          </p>
          <p class="bigpaddingtop"></p>
          <p class="mb-10"></p>
        </v-card>
        <!-- success -->
        <v-card
          v-if="state == 3 && !IsLoggedIn"
          class="d-flex flex-column align-center"
          min-width="400"
          elevation="0"
          color="transparent"
        >
          <p class="notfoundtitle text-center bigpaddingtop">Reset Password</p>
          <p class="mt-3 homepagedesc text-center">
            <v-icon x-large color="green">mdi-check-outline</v-icon>
          </p>
          <p class="homepagedesc text-center mb-10">
            We've sent an email containing a link on how to reset your password
            to {{ email }}.
          </p>
          <p class="bigpaddingtop"></p>
          <p class="bigpaddingtop"></p>
          <p class="mb-8"></p>
        </v-card>
        <!-- default AND logged in -->
        <v-card
          v-if="state == 0 && IsLoggedIn"
          class="d-flex flex-column align-center"
          min-width="400"
          elevation="0"
          color="transparent"
        >
          <p class="notfoundtitle text-center bigpaddingtop">Reset Password</p>
          <p class="homepagedesc text-center">
            It looks like you're already logged in. Go to your Account to reset your password. <br /><br />
            Click the button below to go to your Account.
          </p>
            <p class="mt-1 mb-0">
              <v-btn
                @click="$router.push('/dashboard/account/')"
                width="220px"
                height="50px"
                color="primary"
                >Account</v-btn
              >
            </p>
          <a href="/" class="mt-1"><small>Take me back</small></a>
          <p class="bigpaddingtop text-center"></p>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.notfoundtitle {
  font-size: 30px;
  font-weight: bold;
  line-height: 2.2rem;
  color: rgb(12, 12, 12) !important;
}

.homepagedesc {
  font-size: 16px;
  color: rgb(12, 12, 12) !important;
}

.homepagecard {
  font-size: 17px;
  color: rgb(12, 12, 12) !important;
  font-weight: bolder;
}

.tiercardtitle {
  font-size: 20px;
  color: rgb(12, 12, 12) !important;
  font-weight: bolder;
}

.bigpaddingtop {
  padding-top: 4rem;
}

.pricetitle {
  font-weight: 600;
  font-size: 30px;
}

.pricemonth {
  font-weight: 400;
  font-size: 15px;
}
</style>

<style scoped>
.v-text-field {
  width: 300px;
}
</style>

<script>
import validation from "email-validator";
import config from "../config";

export default {
  name: "PageNotFound",
  data: function () {
    return {
      email: "",
      state: 0,
      isFormValid: false,
      rules: {
        validEmail: (value) =>
          validation.validate(value) == true ||
          "Please enter a valid email address.",
        required: (value) => !!value || "Required.",
        requiredPassword: (value) =>
          value == this.password || "Password must match",
      },
    };
  },
  methods: {
    resetPassword() {
      this.state = 1;
      fetch(`${config.URL}/users/rp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: this.email }),
      }).then((res) => {
        if (res.status === 200) {
          this.state = 3;
        } else {
          this.state = 2;
        }
      });
    },
  },
  computed: {
    IsLoggedIn() {
      return this.$store.state.connected;
    },
  },
};
</script>

