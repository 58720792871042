<template>
  <v-main>
    <v-app-bar app fixed flat dense center color="primary" height="46">
      <div class="
          background
          vertical-nav-header
          d-flex
          items-center
          ps-2
          pt-2
          pb-2
        ">
        <router-link to="/" class="d-flex align-center text-decoration-none">
          <v-img src="../../public/white-logo.png" width="30px" alt="logo" contain eager class="app-logo mr-1"></v-img>
          <v-slide-x-transition v-if="this.$vuetify.breakpoint.smAndUp">
            <h2 class="app-title">Onyshare</h2>
          </v-slide-x-transition>
        </router-link>
      </div>
      <v-spacer></v-spacer>

      <!-- Instantly share captures text -->
      <span v-if="!IsLoggedIn() && this.$vuetify.breakpoint.smAndUp" style="color: white; font-size: 0.85rem"
        class="px-5">Instantly share captures for free</span>

      <!-- Save Download Capture -->
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-1" color="#42A5F5" width="20" v-bind="attrs" v-on="on" @click="downloadCapture(capture)">
            <v-icon class="pa-1 ma-0" color="white" size="20" dark>mdi-download</v-icon>
          </v-btn>
        </template>
        <span>Download Capture</span>
      </v-tooltip>

      <!-- Copy Capture -->
      <v-tooltip bottom color="primary" v-if="IsLoggedIn()">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-1" color="#42A5F5" width="20" v-bind="attrs" v-on="on" @click="copyURL(capture)">
            <v-icon class="pa-1 ma-0" color="white" size="20" dark>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>Copy URL</span>
      </v-tooltip>

      <!-- Delete Capture -->
      <v-tooltip bottom color="primary" v-if="IsLoggedInAndOwn(capture)">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-1" color="#42A5F5" width="20" v-bind="attrs" v-on="on" @click="deleteCaptureButton(capture)">
            <v-icon class="pa-1 ma-0" color="white" size="22" dark>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>

      <!-- Edit Capture -->
      <v-tooltip bottom color="primary" v-if="IsLoggedInAndOwn(capture)">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-1" color="#42A5F5" width="20" v-bind="attrs" v-on="on" @click="editDialog = true">
            <v-icon class="pa-1 ma-0" color="white" size="20" dark>mdi-file-edit</v-icon>
          </v-btn>
        </template>
        <span>Edit Meta Data</span>
      </v-tooltip>

      <!-- Download Onyshare -->
      <v-btn v-if="!IsLoggedIn()" class="mx-1" color="#42A5F5" width="200" v-bind="attrs" v-on="on"
        @click="$router.push('/')">
        <v-icon class="pa-1 ma-0" color="white" size="20" dark>mdi-download</v-icon>
        <span style="color: white; font-size: 0.75rem">Download Onyshare</span>
      </v-btn>

      <v-btn v-if="IsLoggedIn() && !this.$vuetify.breakpoint.smAndUp" class="mx-1" color="#42A5F5" width="20"
        @click="$router.push('/dashboard/captures')">
        <v-icon class="pa-1 ma-0" color="white" size="22" dark>mdi-account</v-icon>
        <span v-if="this.$vuetify.breakpoint.smAndUp" style="color: white; font-size: 0.75rem">Dashboard</span>
      </v-btn>

      <v-btn v-if="IsLoggedIn() && this.$vuetify.breakpoint.smAndUp" class="mx-1" color="#42A5F5" width="140"
        @click="$router.push('/dashboard/captures')">
        <v-icon class="pa-1 ma-0" color="white" size="22" dark>mdi-account</v-icon>
        <span style="color: white; font-size: 0.75rem">Dashboard</span>
      </v-btn>
    </v-app-bar>

    <!-- dialog popup -->
    <v-dialog v-model="deleteDialog" max-width="300">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>Delete capture?</v-toolbar>
          <v-card-text>
            <div class="pt-5 pb-0 mb-0">
              Deleted captures cannot be restored.
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="primary" text @click="deleteDialog = false">Cancel</v-btn>
            <v-btn color="error" text @click="(deleteDialog = false), deleteCapture(captureToDelete)">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <!-- edit popup -->
    <v-dialog v-model="editDialog" max-width="500">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>Edit Meta Data</v-toolbar>
          <v-row class="flex-column align-center justify-center ma-0 pa-0 mt-5">
            <v-text-field placeholder="Title" :value="capture.name" v-model="captureTitle" style="width: 450px" outlined>
              {{ capture.name }}
            </v-text-field>
            <v-textarea class="ma-0" :value="capture.desc" v-model="captureDesc" style="width: 450px" outlined
              height="250px" placeholder="Description (markup and urls allowed)">
              {{ capture.name }}
            </v-textarea>
            <v-select v-if="IsLoggedInAndOwn(capture) && !this.fullsize" v-model="select" class="ma-0 pb-0"
              :items="captureMetaData" outlined item-text="name" style="width: 200px"
              :append-icon="GetMetaIcon(select, capture)" :value="GetMetaLabel(capture)">
              <template v-slot:item="slotProps">
                <v-icon class="mr-2">{{ slotProps.item.icon }}</v-icon>
                {{ slotProps.item.name }}
              </template>
            </v-select>
          </v-row>
          <v-card-actions class="justify-end">
            <v-btn color="primary" text @click="editDialog = false">Cancel</v-btn>
            <v-btn color="green" text @click="(editDialog = false), SendMeta(capture)">Save</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <!-- loading circle -->
    <v-row v-if="this.loading" justify="center" class="mt-15">
      <v-progress-circular class="ma-5" :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </v-row>

    <!-- here -->
    <v-row v-else-if="!this.loading && capture.url !== '' && capture.type != 'txt'"
      class="flex-column align-center justify-center ma-0 pa-0">

      <v-card-title class="mt-1 mb-0 pb-2" v-if="!capture.private">
        {{ capture.name }}
      </v-card-title>
      <v-card-title style="width: 80%" class="app-desc ma-0 pa-0 mb-2" v-if="!capture.private">
        <div v-html="capture.description" v-linkified />
      </v-card-title>

      <!-- video capture -->
      <v-card elevation="10" class="mt-1" ripple:false v-if="capture.type == 'mp4'" height="540px" width="960px">
        <video width="960" height="540" controls muted autoplay loop playsinline>
          <source :src="capture.url" type="video/mp4" />
        </video>
      </v-card>

      <!-- we can resize -->
      <v-card :max-width="GetFullSizeImage()" elevation="10" class="mt-1 hover" ripple:false
        v-if="!this.fullsize && capture.type != 'mp4'">
        <v-img v-if="this.canResize == true" :src="capture.url" @click="FullSizeImage"></v-img>
      </v-card>
      <!-- we cant resize -->
      <v-card :max-width="GetFullSizeImage()" elevation="10" class="mb-1 mt-0" ripple:false
        v-if="!this.fullsize && capture.type != 'mp4'">
        <v-img v-if="this.canResize == false" :src="capture.url"></v-img>
      </v-card>

      <!-- zoomed resize capture -->
      <v-card :max-width="GetFullSizeImage()" elevation="10" class="mt-1 hovermini mb-1" ripple:false
        v-if="this.fullsize && capture.type != 'mp4'">
        <v-img :src="capture.url" @click="FullSizeImage"></v-img>
      </v-card>
    </v-row>
    <v-row v-else-if="!this.loading && capture.url !== '' && capture.type == 'txt'"
      class="flex-column align-center justify-center ma-0 pa-0">
      <v-card-title class="mt-1 mb-0 pb-2" v-if="!capture.private">
        {{ capture.name }}
      </v-card-title>
      <v-card-title style="width: 80%" class="app-desc ma-0 pa-0 mb-2" v-if="!capture.private">
        <div v-html="capture.description" v-linkified />
      </v-card-title>

      <v-btn color="#42A5F5" width="140"
        @click="copyCode(capture)">
        <v-icon class="pa-1 ma-0" color="white" size="22" dark>mdi-content-copy</v-icon>
        <span style="color: white; font-size: 0.75rem">Copy Code</span>
      </v-btn>

      <div v-highlight class="mt-1">
        <CodeArea :code="this.capture.code" :lang="GetCodeLang"></CodeArea>
      </div>
    </v-row>
  </v-main>
</template>

<script>

import config from "../config";
import "vue-toasted/dist/vue-toasted.min.css";
import "../../node_modules/vue-code-highlight/themes/window.css";
import "../../node_modules/vue-code-highlight/themes/prism-coy.css";
import "../../node_modules/prism-es6/components/prism-markup-templating";
import "../../node_modules/prism-es6/components/prism-csharp";
import "../../node_modules/prism-es6/components/prism-markup";
import "../../node_modules/prism-es6/components/prism-css";
import "../../node_modules/prism-es6/components/prism-java";
import "../../node_modules/prism-es6/components/prism-python";
import "../../node_modules/prism-es6/components/prism-clike";
import "../../node_modules/prism-es6/components/prism-c";
import "../../node_modules/prism-es6/components/prism-fsharp";
import "../../node_modules/prism-es6/components/prism-rust";
import "../../node_modules/prism-es6/components/prism-aspnet";
import "../../node_modules/prism-es6/components/prism-r";
import "../../node_modules/prism-es6/components/prism-php";
import CodeArea from '../components/CodeArea.vue'

export default {
  name: "Capture",
  components: {
    CodeArea,
  },
  data: function () {
    return {
      deleteDialog: false,
      editDialog: false,
      deleteSelectedDialog: false,
      loading: true,
      canResize: false,
      capture: {},
      captureImage: null,
      on: null,
      attrs: null,
      select: null,
      item: null,
      captureTitle: "",
      captureDesc: "",
      capturePrivate: true,
      fullsize: false,
      windowWidth: 0,
      captureMetaData: [
        {
          name: "Private metadata",
          icon: "mdi-eye-off-outline",
        },
        {
          name: "Public metadata",
          icon: "mdi-eye-outline",
        },
      ],
    };
  },
  created: function () {
    this.$fetch(
      `${config.URL}/users/capture/${this.$route.params.captureId}`
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          if (!data) {
            this.$router.push("/404");
            return;
          }
          this.capture = data;

          if (data.type == 'txt') {
            fetch(`${this.capture.url}`)
              .then(response => response.text())
              .then((data) => {
                this.capture.code = data;
                this.SetLoading();
              })
          }
          if (data.type != "mp4" && data.type != 'txt') {
            this.captureImage = new Image();

            this.captureImage.onload = (event) => {
              this.windowWidth = window.innerWidth;
              this.CanResizeImage(this.captureImage.width);
              window.addEventListener("resize", this.onResize);
              this.windowWidth = window.innerWidth;
              this.SetLoading();
            };

            this.captureImage.src = this.capture.url;
          } else {
            if (this.capture.type != 'txt') {
              this.SetLoading();
            }
          }

          this.captureTitle = data.name;
          this.captureDesc = data.description;
          this.capturePrivate = data.private;
        });
      }
    }).catch(err => {
      this.$router.push("/404");
    });
  },
  beforeDestroy() {
    //window.removeEventListener("resize", this.onResize);
  },
  computed: {
    GetWindowImageSize() {
      return this.windowWidth * 0.75;
    },
    GetCodeDetails() {
      return this.capture.code;
    },
    GetCodeLang() {
      return this.capture.lang;
    }
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      this.CanResizeImage(this.captureImage.width);
    },
    CanResizeImage(e) {
      if (e <= this.GetWindowImageSize) {
        this.canResize = false;
      } else {
        this.canResize = true;
      }
    },
    SetLoading() {
      this.loading = false;
    },
    FullSizeImage() {
      this.fullsize = !this.fullsize;
    },
    GetFullSizeImage() {
      if (this.fullsize) {
        return "12288px";
      } else {
        return "75%";
      }
    },
    SendMeta(capture) {
      var data = [];
      data.push(this.captureTitle);
      data.push(this.captureDesc);
      data.push(this.capturePrivate);

      this.$fetch(`${config.URL}/users/m/` + capture.file, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status != 200) {
            this.loading = false;
            this.$toasted.show("Failed save metadata", {
              theme: "toasted-primary",
              type: "error",
              position: "top-center",
              duration: 2000,
              iconPack: "mdi",
              icon: "file-alert-outline",
            });
          } else {
            res.json().then((data) => {
              this.capture = data;
              this.captureTitle = data.name;
              this.captureDesc = data.description;
              this.loading = false;

              this.$toasted.show("Successfully saved metadata", {
                theme: "toasted-primary",
                type: "info",
                position: "top-center",
                duration: 2000,
                iconPack: "mdi",
                icon: "file-account-outline",
              });
            });
          }
        })
        .catch((err) => {
          this.$toasted.show("Failed save metadata", {
            theme: "toasted-primary",
            type: "error",
            position: "top-center",
            duration: 2000,
            iconPack: "mdi",
            icon: "file-alert-outline",
          });
        });
      return;
    },
    GetMetaLabel(capture) {
      if (capture.private) {
        return "Private metadata";
      } else {
        return "Public metadata";
      }
    },
    GetMetaIcon(item, capture) {
      if (item == null) {
        if (capture.private) {
          this.select = "Private metadata";
          this.capturePrivate = true;
          return "mdi-eye-off-outline";
        } else {
          this.select = "Public metadata";
          this.capturePrivate = false;
          return "mdi-eye-outline";
        }
      }
      if (item == "Private metadata") {
        this.select = "Private metadata";
        this.capturePrivate = true;
        return "mdi-eye-off-outline";
      }
      if (item == "Public metadata") {
        this.select = "Public metadata";
        this.capturePrivate = false;
        return "mdi-eye-outline";
      }
      return item.id;
    },
    IsPublic(capture) {
      return capture.private === true;
    },
    IsLoggedInAndDontOwn(capture) {
      return (
        this.$store.state.connected &&
        capture.user !== this.$store.state.user.id
      );
    },
    IsLoggedInAndOwn(capture) {
      return (
        this.$store.state.connected &&
        capture.user === this.$store.state.user.id
      );
    },
    IsLoggedIn() {
      return this.$store.state.connected;
    },
    getCaptureURL(captureID) {
      //window.open(config.URL + "/c/" + captureID, '_blank');
      return config.URL + "/c/" + captureID;
    },
    async downloadCapture(capture) {
      const fileName = `https://c.onyshare.com/file/onyshare-eu/${capture.file}.${capture.type}`;
      try {
        const response = await fetch(fileName);
        const blob = await response.blob();
        const url = await URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `${capture.name}.${capture.type}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        this.$toasted.show("Downloading Capture", {
          theme: "toasted-primary",
          type: "info",
          position: "top-center",
          duration: 2000,
          iconPack: "mdi",
          icon: "content-copy",
        });
      } catch (err) {
        console.error(err);
      }
    },
    copyURL(capture) {
      navigator.clipboard.writeText(this.getCaptureURL(capture.file));
      

      this.$toasted.show("Copied URL to Clipboard", {
        theme: "toasted-primary",
        type: "info",
        position: "top-center",
        duration: 2000,
        iconPack: "mdi",
        icon: "content-copy",
      });
    },
    copyCode(capture) {
      navigator.clipboard.writeText(this.capture.code);

      this.$toasted.show("Copied Code to Clipboard", {
        theme: "toasted-primary",
        type: "info",
        position: "top-center",
        duration: 2000,
        iconPack: "mdi",
        icon: "content-copy",
      });
    },
    deleteCaptureButton(capture) {
      this.captureToDelete = capture;
      this.deleteDialog = true;
    },
    deleteCapture(capture) {
      this.$fetch(`${config.URL}/users/d/` + capture.file)
        .then((res) => {
          if (res.status != 200) {
            this.loading = false;

            this.$toasted.show("Failed to delete", {
              theme: "toasted-primary",
              type: "error",
              position: "top-center",
              duration: 2000,
              iconPack: "mdi",
              icon: "trash-can-outline",
            });
          } else {
            res.json().then((data) => {
              this.loading = false;
              this.$router.push("/dashboard/captures");
              this.$toasted.show("Successfully deleted", {
                theme: "toasted-primary",
                type: "info",
                position: "top-center",
                duration: 2000,
                iconPack: "mdi",
                icon: "trash-can-outline",
              });
            });
          }
        })
        .catch((err) => {
          this.$toasted.show("Failed to delete", {
            theme: "toasted-primary",
            type: "error",
            position: "top-center",
            duration: 2000,
            iconPack: "mdi",
            icon: "trash-can-outline",
          });
        });
    },
  },
};
</script>

<style scoped>
div {
  max-width: 100%;
}

.window {
  margin: 0%;
}

.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: white;
}

.app-desc {
  font-size: 1rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: black;
  text-align: center;
  justify-content: center;
}

.hover {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.hovermini {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-out;
}
</style>




