<template>
  <div>
    <v-row class="d-flex justify-center">
      <v-col lg="3" class="d-flex justify-center">
        <v-card
          class="d-flex flex-column align-center"
          width="800"
          elevation="0"
          color="transparent"
        >
          <v-row v-if="state === 0" class="flex-column align-center px-5">
            <p class="validationtitle text-center bigpaddingtop">
              Just a sec...
            </p>
            <v-progress-linear
              class="mt-6"
              style="width: 25%"
              color="primary"
              height="8"
              indeterminate
              rounded
            ></v-progress-linear>
            <p class="mt-5 mb-10 validationdesc text-center">
              We just need a second to work some stuff out.
            </p>

            <p class="bigpaddingtop text-center"></p>
            <p class="bigpaddingtop text-center"></p>
          </v-row>

          <v-row v-else-if="state === 1" class="flex-column align-center px-5">
            <p class="validationtitle text-center bigpaddingtop">
              Something went wrong
            </p>
            <p class="mt-6 homepagedesc text-center">
              <v-icon x-large color="red">mdi-alert-octagon-outline</v-icon>
            </p>
            <p class="mt-5 validationdesc text-center">
              The Reset token is invalid or has expired.<br />
              Please try again by submitting a new request.
            </p>
            <p class="mt-7">
              <v-btn
                @click="$router.push('/')"
                width="160px"
                height="50px"
                color="primary"
                >Home</v-btn
              >
            </p>

            <p class="bigpaddingtop text-center"></p>
          </v-row>

          <v-row v-else-if="state === 2" class="flex-column align-center px-5">
            <p class="validationtitle text-center bigpaddingtop">
              Reset Password
            </p>
            <p class="mt-2 validationdesc text-center">
              Enter your new password below.
            </p>
            <v-form ref="form" v-model="isFormValid">
              <v-text-field
                outlined
                dense
                full-width
                single-line
                class="mb-0 mt-0 py-0"
                name="password"
                label="Password"
                type="password"
                v-model="password"
                :rules="[rules.length, rules.required]"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                full-width
                single-line
                class="mb-0 mt-0 py-0"
                name="password2"
                label="Confirm Password"
                type="password"
                v-model="password2"
                :rules="[rules.length ,rules.requiredPassword, rules.required]"
              ></v-text-field>
              <p class="mt-2">
                <v-btn
                  @click="ResetPassword()"
                  width="220px"
                  height="50px"
                  color="primary"
                  :disabled="!isFormValid"
                  >Change Password</v-btn
                >
              </p>
            </v-form>

            <p class="bigpaddingtop text-center"></p>
          </v-row>
          <v-row v-else-if="state === 3" class="flex-column align-center px-5">
            <p class="validationtitle text-center bigpaddingtop">
              Password Changed
            </p>
            <p class="mt-6 homepagedesc text-center">
              <v-icon x-large color="green">mdi-check-outline</v-icon>
            </p>
            <p class="mt-5 validationdesc text-center">
              Your password has been changed.<br />You may now log
              in and use Onyshare.
            </p>
            <p class="mt-7">
              <v-btn
                @click="$router.push('/')"
                width="200px"
                height="50px"
                color="primary"
                >Home</v-btn
              >
            </p>

            <p class="bigpaddingtop text-center"></p>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import config from "../config";
export default {
  name: "Validation",
  data: function () {
    return {
      state: 0,
      message: "",
      password: "",
      password2: "",
      isFormValid: false,
      rules: {
        length: (value) => value.length >= 8 || "Must be 8 characters or more",
        required: (value) => !!value || "Required.",
        requiredPassword: (value) =>
          value == this.password || "Password must match",
      },
    };
  },
  methods: {
    ResetPassword() {
      fetch(`${config.URL}/users/resetpassword`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: this.$route.params.id, password: this.password }),
      }).then((res) => {
      res.json().then((data) => {
        this.state = data.state;
      });
    });
    },
  },
  mounted: function () {
    fetch(`${config.URL}/users/reset`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: this.$route.params.id }),
    }).then((res) => {
      res.json().then((data) => {
        this.state = data.state;
      });
    });
  },
};
</script>

<style>
.validationtitle {
  font-size: 35px;
  font-weight: bold;
  line-height: 2.2rem;
  color: rgb(12, 12, 12) !important;
}

.validationdesc {
  font-size: 18px;
  font-weight: normal;
  line-height: 2.2rem;
  color: rgb(12, 12, 12) !important;
}

.bigpaddingtop {
  padding-top: 5.5rem;
}
</style>
